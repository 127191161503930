<template>
  <div class="service-info p-col-6">
    <div class="p-d-flex checkbox">
      <p class="p-mr-5 font-semibold">{{ i18n.$t('Questionnaire') }}</p>
      <Checkbox v-model="items.is_question" :binary="true" />
    </div>

    <Questionnaire
      :show="items.is_question"
      :items="items.questions"
      @save="save"
    />

    <div class="p-mt-3 p-d-flex checkbox contact-info">
      <p class="p-mr-5 font-semibold">{{ i18n.$t('Full Contact Info') }}</p>
      <div class="p-field-radiobutton">
        <RadioButton
          id="is_fill_contact_yes"
          name="is_fill_contact"
          :value="true"
          v-model="items.is_fill_contact"
        />
        <label for="is_fill_contact">Yes</label>
      </div>
      <div class="p-field-radiobutton p-ml-3">
        <RadioButton
          id="is_fill_contact_no"
          name="is_fill_contact"
          :value="false"
          v-model="items.is_fill_contact"
        />
        <label for="is_fill_contact">No</label>
      </div>
    </div>
  </div>
</template>

<script>
import useI18n from '@/plugins/useI18n';
import Questionnaire from '@/components/Service/Questionnaire';

export default {
  components: {
    Questionnaire
  },
  props: {
    items: {
      type: Object
    }
  },
  setup(props) {
    const { i18n } = useI18n();

    function save(e) {
      props.items.questions = e;
    }

    return {
      i18n,
      save
    };
  }
};
</script>

<style lang="scss" scoped>
.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.contact-info, .checkbox, ::v-deep(.required-field) {
  display: flex;
  align-items: center;
  p {
    height: 14px;
    display: flex;
    align-items: center;
  }
  .p-field-checkbox,
  .p-field-radiobutton {
    margin-bottom: 0;
  }
}
</style>