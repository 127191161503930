<template>
  <div class="p-col-12 questionare" v-if="show">
    <div class="overlay" v-if="readOnly"></div>
    <div class="bar" :class="{ 'p-mt-3': !readOnly }">
      <p class="font-semibold title">{{ i18n.$t('Question') }} #{{ step }}</p>
      <div class="action">
        <Button
          icon="pi pi-eye"
          class="p-button-secondary btn-add p-mr-2"
          @click="showPreview = true"
          v-if="questions"
        />
        <Button
          icon="pi pi-plus"
          class="p-button-success btn-add p-mr-2"
          @click="addQuestion"
          v-if="!readOnly"
        />
        <ButtonConfirm @accept="deleteQuestion">
          <Button
            icon="pi pi-trash"
            class="p-button-danger p-mr-2"
            v-if="!readOnly"
          />
        </ButtonConfirm>
        <Button
          icon="pi pi-angle-left"
          class="p-mr-2 pagination"
          :disabled="step == 1"
          @click="prevStep"
        />
        <Button
          icon="pi pi-angle-right"
          iconPos="right"
          class="pagination"
          :disabled="questions.length == step"
          @click="nextStep"
        />
      </div>
    </div>
    <div class="p-mt-3 p-d-flex checkbox contact-info">
      <p class="p-mr-5 font-semibold">{{ i18n.$t('Type') }}</p>
      <div class="p-field-radiobutton">
        <RadioButton
          :value="types[0].type"
          v-model="type"
          @change="changeType"
        />
        <label for="is_fill_contact">{{ types[0].label }}</label>
      </div>
      <div class="p-field-radiobutton p-ml-3">
        <RadioButton
          :value="types[1].type"
          v-model="type"
          @change="changeType"
        />
        <label for="is_fill_contact">{{ types[1].label }}</label>
      </div>
    </div>
    <div class="flex required-field p-mt-3">
      <p class="p-mr-3 font-semibold">Required Field</p>
      <Checkbox v-model="currentQuestion.mandatory" :binary="true" />
    </div>
    <div class="mt-5">
      <div class="form-group" v-if="currentQuestion">
        <span class="p-float-label p-mt-5">
          <InputText type="text" v-model="currentQuestion.question" />
          <label for="field">{{ i18n.$t('Question') }}</label>
        </span>

        <!-- <span
          class="p-float-label p-mt-5"
          v-if="currentQuestion.type == 'Question'"
        >
          <Textarea
            id="field"
            v-model="currentQuestion.response"
            rows="5"
            cols="30"
          />
          <label for="field">{{ i18n.$t('Response') }}</label>
        </span> -->

        <div
          class="form-input p-mt-5"
          v-if="currentQuestion.type != 'Question'"
        >
          <div
            class="p-float-label checkbox p-mb-5"
            v-for="(el, index) in currentQuestion.response"
            :key="el"
          >
            <InputText id="field" type="text" v-model="el.res" />
            <label for="field"
              >{{ i18n.$t('Response') }} #{{ index + 1 }}</label
            >
          </div>
          <div class="p-d-flex">
            <ButtonConfirm @accept="deleteLastResponse" style="width: 150px">
              <Button
                label="Delete Last"
                icon="pi pi-trash"
                iconPos="right"
                class="p-button-danger p-mr-2"
                v-if="!readOnly"
              />
            </ButtonConfirm>
            <Button
              style="width: 150px"
              label="Add Response"
              icon="pi pi-plus"
              iconPos="right"
              class="p-button-success btn-add p-mr-2"
              @click="addResponse"
              v-if="!readOnly"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <Dialog
    header="Preview"
    v-model:visible="showPreview"
    :maximizable="true"
    :modal="true"
    :style="{ width: '50vw' }"
  >
    <questionnaire-preview :items="questions" />
  </Dialog>
</template>

<script>
import useI18n from '@/plugins/useI18n';
import { ref, reactive, computed } from 'vue';
import { useRouter } from 'vue-router';
import QuestionnairePreview from './QuestionnairePreview.vue';
export default {
  components: { QuestionnairePreview },
  props: {
    items: {
      type: Object
    },
    show: {
      type: Boolean
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const { i18n } = useI18n();

    const router = useRouter();
    let i = 0;
    router.beforeEach((to, from, next) => {
      if (from.name == 'ServiceAddStep2' || from.name == 'ServiceEditStep2') {
        save();
      }
      next();
    });

    const types = [
      {
        type: 'Question',
        label: 'Question'
      },
      {
        type: 'QCM',
        label: 'QCM'
      }
    ];

    const availability =
      props.items && props.items[0].question && props.items[0].question != '';

    const questions = availability
      ? reactive(props.items)
      : reactive([
          { question: '', response: '', mandatory: false, type: 'Question' }
        ]);

    const type = ref('Question');

    const step = ref(1);
    const showPreview = ref(false);

    const currentQuestion = computed(() => questions[step.value - 1]);
    const currentIndex = computed(() => step.value - 1);

    if (availability) {
      type.value = currentQuestion.value.type;
    }

    function changeType() {
      const example = [
        {
          question: '',
          response: '',
          mandatory: false,
          type: 'Question'
        },
        {
          question: '',
          response: [{ res: '' }, { res: '' }],
          mandatory: false,
          type: 'QCM'
        }
      ];
      const value = type.value == 'Question' ? example[0] : example[1];
      questions[currentIndex.value] = value;
    }

    function addResponse() {
      const item = { res: 'New response' };
      questions[currentIndex.value].response.push(item);
    }

    function deleteLastResponse() {
      const response = questions[currentIndex.value].response;
      const length = response.length;
      const indexLast = length - 1;

      if (length > 2) {
        questions[currentIndex.value].response.splice(indexLast, 1);
      }
    }

    function nextStep() {
      step.value += 1;
      updateTypeValue();
    }

    function prevStep() {
      step.value -= 1;
      updateTypeValue();
    }

    function updateTypeValue() {
      type.value = currentQuestion.value.type;
    }

    function deleteQuestion() {
      if (questions.length > 1) {
        questions.splice(currentIndex.value, 1);
        step.value -= step.value > 1 ? 1 : 0;
      }
    }
    function addQuestion() {
      let item = { question: '', response: '', type: 'Question' };
      questions.push(item);
      step.value = questions.length;
      type.value = 'Question';
    }

    function save() {
      emit('save', questions);
    }

    return {
      i18n,
      types,
      type,
      step,
      questions,
      currentQuestion,
      nextStep,
      changeType,
      addResponse,
      deleteLastResponse,
      prevStep,
      deleteQuestion,
      addQuestion,
      save,
      showPreview
    };
  }
};
</script>

<style lang="scss" scoped>
.bar {
  display: flex;
  justify-content: space-between;
}
.action {
  display: flex;
}
.title {
  font-size: 25px;
}
.questionare {
  position: relative;
  .overlay {
    height: calc(100% - 55px);
    width: 100%;
    z-index: 100;
    position: absolute;
    top: 55px;
  }
}
.required-field {
  display: flex;
  align-items: center;
}
</style>