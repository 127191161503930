<template>
  <Form :items="items" />
</template>

<script>
import Form from '../Form/Step2.vue';

export default {
  props: {
    items: {
      type: Object,
    }
  },
  components: {
    Form
  }
};
</script>