<template>
  <div class="p-col-12 questionare">
    <Accordion :activeIndex="0">
      <AccordionTab v-for="(currentQuestion, index) of questions" :key="index">
        <template #header>
          <span>{{ i18n.$t('Question') + ' #' + (index + 1) }}</span>
        </template>
        <div>
          <div class="p-mt-3 p-d-flex checkbox contact-info">
            <p class="p-mr-5 font-semibold">{{ i18n.$t('Type') }}</p>
            <div class="p-field-radiobutton">
              <RadioButton
                :value="types[0].type"
                v-model="currentQuestion.type"
                :disabled="true"
              />
              <label for="is_fill_contact">{{ types[0].label }}</label>
            </div>
            <div class="p-field-radiobutton p-ml-3">
              <RadioButton
                :value="types[1].type"
                v-model="currentQuestion.type"
                :disabled="true"
              />
              <label for="is_fill_contact">{{ types[1].label }}</label>
            </div>
          </div>
          <div class="flex required-field p-mt-3">
            <p class="p-mr-3 font-semibold">Required Field</p>
            <Checkbox
              v-model="currentQuestion.mandatory"
              :binary="true"
              :disabled="true"
            />
          </div>
          <div class="mt-5">
            <div class="form-group" v-if="currentQuestion">
              <span class="p-float-label p-mt-5">
                <InputText
                  type="text"
                  v-model="currentQuestion.question"
                  :readonly="true"
                />
                <label for="field">{{ i18n.$t('Question') }}</label>
              </span>

              <!-- <span
            class="p-float-label p-mt-5"
            v-if="currentQuestion.type == 'Question'"
          >
            <Textarea
              id="field"
              v-model="currentQuestion.response"
              rows="5"
              cols="30"
            />
            <label for="field">{{ i18n.$t('Response') }}</label>
          </span> -->

              <div
                class="form-input p-mt-5"
                v-if="currentQuestion.type != 'Question'"
              >
                <div
                  class="p-float-label checkbox p-mb-5"
                  v-for="(el, index) in currentQuestion.response"
                  :key="el"
                >
                  <InputText
                    id="field"
                    type="text"
                    v-model="el.res"
                    :readonly="true"
                  />
                  <label for="field"
                    >{{ i18n.$t('Response') }} #{{ index + 1 }}</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </AccordionTab>
    </Accordion>
  </div>
</template>

<script>
import useI18n from '@/plugins/useI18n';
import { ref, reactive, computed } from 'vue';
import { useRouter } from 'vue-router';
export default {
  props: {
    items: {
      type: Object
    },
    show: {
      type: Boolean
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { i18n } = useI18n();
    const types = [
      {
        type: 'Question',
        label: 'Question'
      },
      {
        type: 'QCM',
        label: 'QCM'
      }
    ];

    const availability = props.items && props.items[0].question;

    const questions = availability
      ? reactive(props.items)
      : reactive([
          { question: '', response: '', mandatory: false, type: 'Question' }
        ]);

    const type = ref('Question');

    const step = ref(1);

    const currentQuestion = computed(() => questions[step.value - 1]);
    const currentIndex = computed(() => step.value - 1);

    if (availability) {
      type.value = currentQuestion.value.type;
    }

    return {
      i18n,
      questions,
      types
    };
  }
};
</script>

<style lang="scss" scoped>
input {
  width: 100%;
}
.bar {
  display: flex;
  justify-content: space-between;
}
.action {
  display: flex;
}
.title {
  font-size: 25px;
}
.questionare {
  position: relative;
  .overlay {
    height: calc(100% - 55px);
    width: 100%;
    z-index: 100;
    position: absolute;
    top: 55px;
  }
}
.required-field {
  display: flex;
  align-items: center;
}
</style>